import React from 'react'
import { Select } from 'antd'
import NumberFormat from "react-number-format";

function Editable({
    type = 'text',
    defaultValue = '',
    onChange = null,
    focusField = false,
    options = [],
    size = 80,
    maxLength = 100,
    onKeyDown = null,
    autoFocus = false,
    tabIndex = 1,
    preFix = '',
    postFix = ''
}) {
    return (
        <>
            {type === 'text' && focusField ?
                <input
                    tabIndex={tabIndex}
                    style={{ width: size }}
                    className='d-inline'
                    autoFocus={autoFocus}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    maxLength={maxLength}
                    onKeyDown={onKeyDown}
                />
                : type === 'select' && focusField ?
                    <Select
                        tabIndex={tabIndex}
                        style={{ width: size }}
                        defaultValue={defaultValue}
                        options={options}
                        onChange={onChange}
                    >
                    </Select>
                    : type === 'value' && focusField ?
                        <NumberFormat
                            style={{ width: size }}
                            tabIndex={tabIndex}
                            thousandSeparator={true}
                            prefix={"$ "}
                            variant="outlined"
                            defaultValue={defaultValue}
                            allowEmptyFormatting
                            autoFocus={autoFocus}
                            onValueChange={onChange}
                            onKeyDown={onKeyDown}
                        />
                        :
                        <span tabIndex={tabIndex} className='value'>{defaultValue ?`${preFix} ${defaultValue}${postFix}`: '-'}</span>
            }
        </>
    )
}

export default Editable