import React, { useState } from 'react';
import JobDetail from '../expandedCard/jobDetail';
import { useDispatch } from 'react-redux'
import { Skeleton } from 'antd'

const ExpandedCard = (props) => {

    const [currentNoteIndex, setCurrentNoteIndex] = useState(null);
    const [currentNote, setCurrentNote] = useState(null);
    const dispatch = useDispatch()

    const handleUpdate = (isOpen = false, noteIndex = null, note = null) => {

        if (isOpen) {
            dispatch({ type: 'REPLY_NOTE', payload: true })
            setCurrentNoteIndex(noteIndex);
            setCurrentNote(note);
        }
    };

    return (
        <div className="expandedcard_content">
            {props.loading ?
                <Skeleton />
                : Object.keys(props.item).length > 0 &&
                <JobDetail
                    replyBack={handleUpdate}
                    isCardChanged={props.isCardChanged}
                    item={props.item}
                    changeBackground={props.changeBackground}
                    currentUser={props.currentUser}
                    currentNoteIndex={currentNoteIndex}
                    currentNote={currentNote}
                    onArchiveJob={props.onArchiveJob}
                />
            }
        </div>
    )
};

export default ExpandedCard;