import React, { useEffect, useState } from 'react'
import {useForm, Controller} from 'react-hook-form'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Select } from 'antd'
import Modal from 'react-bootstrap/Modal'
import { Input } from 'reactstrap'
import { PhoneInputHF } from '../../components/common/masked-hook-form-inputs.js'
import { OutlinedButton, FilledButton } from '@oneblinktech-org/helios-base'
import { checkmark } from '../../fakeData/svgFiles'
import ScrollContainer from 'react-indiana-drag-scroll'
import { notification } from 'antd';
import { connect } from 'react-redux'
import { SketchPicker } from 'react-color'
import Block from '../common/block'
import states from '../../fakeData/state'
import {
    getColorList,
} from '../../store/job/actions'
import { weekTitles } from '../../helpers/getWeekDays'
import { createApiClient } from '@oneblinktech-org/helios-base';

const apiUrl = process.env.REACT_APP_API_URL;
const { get, post } = createApiClient({ apiUrl });
const { Option } = Select

const DATASET = {
    cards: {},
    cardOrder: []
}

const fixedCardIds = ['1', '12']

function DragDropCards({cards, cardOrder, setCards, setCardOrder}) {
    const [show, setShow] = useState(false);
    const [editing, setEditing] = useState(null)
    const [selectedCard, setSelectedCard] = useState({
        jobCount: 0,
        id: null
    })
    const [status, setStatus] = useState(null)
    const onDragEnd = (result) => {

        const { destination, source, draggableId, type } = result;
    
        if (
          !destination ||
          (destination.droppableId === source.droppableId &&
            destination.index === source.index)
        ) {
          return
        }

        if (fixedCardIds.includes(cardOrder[destination.index]) || fixedCardIds.includes(cardOrder[source.index])) {
            return;
        }
        
        reorderCards(source, destination, draggableId)
    }

    const reorderCards = (source, destination, draggableId) => {
        const newCardOrder = Array.from(cardOrder);
        newCardOrder.splice(source.index, 1);
        newCardOrder.splice(destination.index, 0, draggableId);
        setCardOrder(newCardOrder);
    }

    const onRemoveCard = (cardID) => {
        const token = localStorage.getItem('h-access_token')
        
        get('/jobs/getJobCounts/' + cardID, {
            "Authorization": `Bearer ` + token
        }).then(result => {
            setSelectedCard(() => ({jobCount: result.count, id: cardID}))
            if (result.count > 0) {
                setStatus(cardID)
                setShow(true)
            } else {
                setStatus(null)
                let alertDesc = "Removed " + cards[cardID]['name']
                manageColumnAPI(alertDesc, cardID, null)
            }
        }).catch(e => {
            throw e
        })
    }

    const onSaveNameEdit = (cardID, newName) => {
        if (newName !== cards[cardID].name) {
          setCards({
            ...cards,
            [cardID]: {
              ...cards[cardID],
              name: newName
            }
          });
        }
    }

    const handleClose = () => setShow(false)

    const handleRemoveColumn = () => {
        
        if (selectedCard.id == status) {
            return;
        }

        let alertDesc = 'Moved jobs of "' + cards[selectedCard.id]['name'] + '" in "' + cards[status]['name'] + '"'
        manageColumnAPI(alertDesc, selectedCard.id, status)
        
    }
    
    const manageColumnAPI = (description, from, to) => {
        const token = localStorage.getItem('h-access_token')
        
        get('/jobs/manageStatus/' + from + '/' + to, {
            "Authorization": `Bearer ` + token
        }).then(result => {
            if (result.message == 'success') {
                notification.open({
                    message: 'Alert!',
                    description: description,
                });
                setSelectedCard(() => ({jobCount: 0, id: null}));
                const newCardOrder = cardOrder.filter((id) => id != from);
                setCardOrder(newCardOrder);
                delete cards[from];
                setCards(cards);
                setShow(false);
            }
        }).catch(e => {
            throw e
        })
    }

    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="all-cards" direction="horizontal">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className="d-flex flex-column flex-md-row py-2">
                    {cardOrder.map((id, index) => {
                        const card = cards[id];
                        return (
                        <Draggable draggableId={card.id.toString()} index={index} key={card.id.toString()} isDragDisabled={card.id === fixedCardIds[0] || card.id === fixedCardIds[1]}>
                            {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                
                                style={{
                                    userSelect: "none",
                                    opacity: snapshot.isDragging ? '1' : '0.8',
                                    ...provided.draggableProps.style
                                }}
                                id={card.id}
                                className="card-container px-2"
                            >
                                <div className="d-flex flex-row align-items-center justify-content-between">
                                    <img className={(fixedCardIds.includes(card.id) ? 'invisible' : '') + " drag-img"} alt="" src={require("../../assets/images/drag.svg").default} {...provided.dragHandleProps}/>
                                    {
                                        !fixedCardIds.includes(card.id) &&
                                        <img className="pointer" onClick={() => onRemoveCard(card.id)} alt="" src={require("../../assets/images/recycle.svg").default} />
                                    }
                                </div>
                                <div className="drag-title mt-3 pb-3">
                                    {(!fixedCardIds.includes(card.id) && editing === card.id) ? (
                                        <input
                                            type="text"
                                            autoFocus
                                            key={card.id}
                                            value={card.name}
                                            onChange={(e) => onSaveNameEdit(card.id, e.target.value)}
                                            onKeyPress={(event) => {
                                                if (event.key === "Enter" || event.key === "Escape") {
                                                    setEditing(null)
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                }
                                            }}
                                            onBlur={() => setEditing(null)}
                                            fontSize="1.5em"
                                            margin="20px 0 20px 8px"
                                        />
                                    ) : (
                                        <div
                                            onDoubleClick={() => setEditing(card.id)}
                                        >
                                            {card.name}
                                        </div>
                                    )}
                                </div>
                                <div className="drag-block mt-3" />
                            </div>
                            )}
                        </Draggable>
                        );
                    })}
                    {provided.placeholder}
                    </div>
                )}
                </Droppable>
            </DragDropContext>
            <Modal
                size="sm"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <p>There are <strong>{selectedCard.jobCount} jobs</strong> currently in <strong>'{(cardOrder.length > 0 && selectedCard.id != null) ? cards[selectedCard.id]['name'] : ""}'</strong> status. If you still want to delete this status, please choose which status to move them to below, or click <strong>Cancel</strong> to go back.</p>
                    <div className='col-6 d-flex flex-row justify-content-center align-items-center position-relative edit-wrap'>
                        <label className="mr-3">Status:</label>
                        <Input
                            type="select"
                            name="custom_state"
                            id="custom_state"
                            className="select-box-blue-round gray-bg"
                            autoComplete="select"
                            bsSize="default"
                            value={status}
                            onChange={(e) => {
                                setStatus(e.target.value)
                            }}
                        >
                            {
                                cardOrder.map((id, index) => {
                                    let card = cards[id]
                                    return (
                                        <option value={card.id} key={index} >{card.name}</option>
                                    )
                                })
                            }
                        </Input>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <OutlinedButton
                    title='Save Changes'
                    type="button"
                    svgFile={checkmark}
                    clickEvent={(e) => handleRemoveColumn()}
                />
                <FilledButton
                    title='Cancel'
                    clickEvent={(e) => handleClose()}
                />
                </Modal.Footer>
            </Modal>
        </div>
    )
}

const MyShopInformation = ({sendCards, colorList, data, register, getValues, setValue, control, errors, dirtyFields, watch, reset}) => {

    const [shopToggle, setShopToggle] = useState(localStorage.getItem('shopToggle') ? JSON.parse(localStorage.getItem('shopToggle')) === true : true)
    const [settingToggle, setSettingToggle] = useState(localStorage.getItem('settingToggle') ? JSON.parse(localStorage.getItem('settingToggle')) === true : true)
    const [displayColor, setDisplayColor] = useState([false, false, false, false, false])
    const [colors, setColors] = useState([])
    const [dataset, _] = useState(DATASET)
    const [cards, setCards] = useState(dataset.cards);
    const [cardOrder, setCardOrder] = useState(dataset.cardOrder);
    const [dayOperation, setDayOperation] = useState({sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false})
    const watchAllFields = watch()
    
    useEffect(() => {
        if (Object.keys(data).length > 0) {

            if (data?.helios_organization_setting?.day_operation != null && data?.helios_organization_setting?.day_operation != "" && Object.keys(data?.helios_organization_setting?.day_operation).length > 0) {
                setDayOperation(JSON.parse(data?.helios_organization_setting?.day_operation))
            }
            
            if (JSON.parse(data?.helios_organization_setting?.job_colors) != null) {
                setValue('jobColors', JSON.parse(data?.helios_organization_setting?.job_colors))
                setColors(JSON.parse(data?.helios_organization_setting?.job_colors))
            } else {
                setColors([])
                setValue('jobColors', [])
            }

            let statusC = JSON.parse(data?.helios_organization_setting?.status_columns)
            if (statusC !=null && statusC != "" && Object.keys(statusC).length > 0){
                setCards(statusC.cards)
                setCardOrder(statusC.cardOrder)
            } else {
                setCards({})
                setCardOrder([])
            }
        }
    }, [data])

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            manageSettings({dayOperation: JSON.stringify(dayOperation)})
        }
    }, [dayOperation])

    useEffect(() => {
        if (cardOrder.length > 0) {
            
            let statusColumn = {
                'cards': cards,
                'cardOrder': cardOrder
            }
            
            sendCards(cards, cardOrder)
            manageSettings({statusColumn: JSON.stringify(statusColumn)})
        }
    }, [cardOrder, cards])

    const manageSettings = (data) => {
        const token = localStorage.getItem('h-access_token')

        post('/organizations/manageSettings', data, 
        {"Authorization": `Bearer ` + token}
        ).then(result => {

        }).catch(e => {
            throw e
        })
    }

    const onAddNewCard = () => {
        const newCard = {
          id: parseInt(Object.keys(cards).pop()) + 1,
          name: "**New**",
          jobIds: []
        };
        const newCardOrder = Array.from(cardOrder);
        newCardOrder.splice(newCardOrder.indexOf(fixedCardIds[0]) + 1, 0, newCard.id)
        setCards({
          ...cards,
          [newCard.id]: newCard
        });
        setCardOrder(newCardOrder);
    }

    const handlePickerClick = (index) => {
        let colorsCopy = [...displayColor]
        colorsCopy[index] = !colorsCopy[index]
        setDisplayColor(colorsCopy)
    };

    const handlePickerClose = (index) => {
        let colorsCopy = [...displayColor]
        colorsCopy[index] = false
        setDisplayColor(colorsCopy)
      };
    
    const handlePickerChange = (color, index) => {
        let colorsCopy = [...colors]
        colorsCopy[index]['color'] = color.hex
        manageSettings({jobColors: JSON.stringify(colorsCopy)})
        setColors(colorsCopy)
        setValue('jobColors', colorsCopy)
      };

    return (
        <div>
            <Block
                title='My Shop Information'
                isOpen={shopToggle}
                onToggle={() => {
                    let shopToggleV = !shopToggle
                    localStorage.setItem('shopToggle', shopToggleV)
                    setShopToggle(shopToggleV)
                }}
            >
                <div className="content">
                    {/*<div className='edit-wrap w-100 px-3'>*/}
                    {/*    <div className='d-flex flex-row align-items-center'>*/}
                    {/*        <img className='d-inline-flex mr-2' src={require('../../assets/images/search.svg').default} alt='' />*/}
                    {/*        <input placeholder='Search organization name, address ...' className='w-100' value='' />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="px-3 py-2 org-name">{Object.keys(data).length > 0 ? data.name : ''}</div>*/}
                </div>
                <div className='content row mt-3'>
                    <div className='col-12 col-md-3 edit-wrap'>
                        <label>Shop Name</label>
                        <div>
                            <input
                                type="text"
                                name="shopName"
                                id="shopName"
                                autoComplete="shopName"
                                {...register('shopName', { 
                                    required: true, 
                                })}
                            />
                            <img className={Object.keys(dirtyFields).includes('shopName') && dirtyFields['shopName'] == true ? 'edited' : ''} src={require('../../assets/images/pencil.svg').default} alt='' />
                        </div>
                        {errors.shopName && <small className='text-danger error-text'>This field is required</small>}
                    </div>
                    <div className='col-12 col-md-3 edit-wrap'>
                        <label>Address</label>
                        <div>
                            <input
                                type="text"
                                name="shopAddr"
                                id="shopAddr"
                                autoComplete="shopAddr"
                                {...register('shopAddr', { 
                                    required: true, 
                                })}
                            />
                            <img className={Object.keys(dirtyFields).includes('shopAddr') && dirtyFields['shopAddr'] == true ? 'edited' : ''} src={require('../../assets/images/pencil.svg').default} alt='' />
                        </div>
                        {errors.shopAddr && <small className='text-danger error-text'>This field is required</small>}
                    </div>
                    <div className='col-12 col-md-3 position-relative edit-wrap'>
                        <label>State</label>
                        <Controller
                            render={({ field: { onChange, value, name, ref } }) => {
                                return (
                                    <>
                                        <Select
                                            value={value}
                                            style={{width: '100%'}}
                                            placeholder='Choose state'
                                            onChange={(value) => {
                                                onChange(value)
                                            }}
                                        >
                                            {
                                                states.map((item, index) => {
                                                    return (
                                                        <option value={item.name} key={index}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </>
                                )
                            }}
                            control={control}
                            name='shopState'
                        />
                        {errors.shopState && <small className='text-danger error-text'>This field is required</small>}
                    </div>
                    <div className='col-12 col-md-3 edit-wrap'>
                        <label>Zip</label>
                        <div>
                            <input
                                type="text"
                                name="shopZip"
                                id="shopZip"
                                autoComplete="shopZip"
                                {...register('shopZip', { 
                                    required: true,
                                    pattern: {
                                        value: /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/,
                                        message: "Invalid zip code"
                                    }
                                })}
                            />
                            <img className={Object.keys(dirtyFields).includes('shopZip') && dirtyFields['shopZip'] == true ? 'edited' : ''} src={require('../../assets/images/pencil.svg').default} alt='' />
                        </div>
                        {errors.shopZip && <small className='text-danger error-text'>{errors.shopZip?.message}</small>}
                    </div>
                </div>
                <div className='content row mt-3'>
                    <div className='col-12 col-md-3 edit-wrap'>
                        <label>City</label>
                        <div>
                            <input
                                type="text"
                                name="shopCity"
                                id="shopCity"
                                autoComplete="shopCity"
                                {...register('shopCity', { 
                                    required: true, 
                                })}
                            />
                            <img className={Object.keys(dirtyFields).includes('shopCity') && dirtyFields['shopCity'] == true ? 'edited' : ''} src={require('../../assets/images/pencil.svg').default} alt='' />
                        </div>
                        {errors.shopCity && <small className='text-danger error-text'>This field is required</small>}
                    </div>
                    <div className='col-12 col-md-3 edit-wrap'>
                        <label>Phone</label>
                        <div>
                            <PhoneInputHF
                                name="shopPhone"
                                control={control}
                                mask="_"
                                format="+1 (###) ### ####"
                            />
                            <img className={Object.keys(dirtyFields).includes('shopPhone') && dirtyFields['shopPhone'] == true ? 'edited' : ''} src={require('../../assets/images/pencil.svg').default} alt='' />
                        </div>
                        {errors.shopPhone && <small className='text-danger error-text'>{errors?.shopPhone?.message}</small>}
                    </div>
                </div>
            </Block>
            <Block
                title='Settings'
                isOpen={settingToggle}
                onToggle={() => {
                    let settingToggleV = !settingToggle
                    localStorage.setItem('settingToggle', settingToggleV)
                    setSettingToggle(settingToggleV)
                }}
            >
                <div className="row">
                  {/* HIDDEN FOR NOW. We have to fix HES-432 first */}
                    {/*<div className="weekDays-selector col-12 col-md-6">*/}
                    {/*    <span>DAYS OF OPERATION</span>*/}
                    {/*    {*/}
                    {/*        weekTitles.map((day, index) => */}
                    {/*            <>*/}
                    {/*                <input*/}
                    {/*                    key={index}*/}
                    {/*                    name="dayOperation" */}
                    {/*                    value={day.toLowerCase()} */}
                    {/*                    type="checkbox" */}
                    {/*                    className="weekday"*/}
                    {/*                    id={"weekday-" + day.toLowerCase()}*/}
                    {/*                    checked={dayOperation[day.toLowerCase()]}*/}
                    {/*                    onChange={() => {*/}
                    {/*                        setDayOperation({*/}
                    {/*                            ...dayOperation,*/}
                    {/*                            [day.toLowerCase()]: !dayOperation[day.toLowerCase()]*/}
                    {/*                        })*/}
                    {/*                    }}*/}
                    {/*                />*/}
                    {/*                <label htmlFor={"weekday-" + day.toLowerCase()}>{day?.charAt(0)}</label>*/}
                    {/*            </>*/}
                    {/*        )*/}
                    {/*    }*/}
                    {/*</div>*/}
                    <div className="weekDays-selector col-12 col-md-6 d-flex align-items-center">
                        <span>JOB COLORS</span>
                        {
                            colors.length > 0 &&
                            colors.map((item, index) => {
                                return  (
                                    <Controller
                                        render={() => {
                                            return (
                                                <div>
                                                    <span
                                                        key={item.id}
                                                        style={{backgroundColor: item.color ? item.color : '#ffffff'}}
                                                        onClick={ () =>  handlePickerClick(index)}
                                                        className="board-color_radio mr-2 d-inline-block pointer"
                                                    >
                                                    </span>
                                                    { displayColor[index] ? 
                                                        <div className="picker-popover">
                                                            <div className="picker-cover" onClick={ () => handlePickerClose(index) }/>
                                                            <SketchPicker color={ item.color } onChange={ (color) => handlePickerChange(color, index) } />
                                                        </div> : null
                                                    }
                                                </div>
                                            )
                                        }}
                                        control={control}
                                        name='jobColors'
                                        innerRef={register('jobColors', { required: true })}
                                        key={index}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
                <div className="mt-5">
                    <div className="d-flex justify-content-between custom-status">
                        <span>Customize Status Columns</span>
                        <svg className="pointer" onClick={onAddNewCard} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.66666 6.33333V1.33663C6.66666 0.598429 7.25848 0 8 0C8.73638 0 9.33333 0.60768 9.33333 1.33663V6.33333H14.6634C15.4016 6.33333 16 6.92515 16 7.66667C16 8.40305 15.3923 9 14.6634 9H9.33333V14.6634C9.33333 15.4016 8.74151 16 8 16C7.26362 16 6.66666 15.3923 6.66666 14.6634V9H1.33663C0.59843 9 0 8.40818 0 7.66667C0 6.93029 0.607681 6.33333 1.33663 6.33333H6.66666Z" fill="#3F3B3B"/>
                        </svg>
                    </div>
                    <ScrollContainer
                        className="d-flex flex-row justify-content-between drag_drop production-board-table mt-3"
                        ignoreElements=".drag-img, *[prevent-drag-scroll]"
                        hideScrollbars={false}
                    >
                        <DragDropCards
                            cards={cards}
                            cardOrder={cardOrder}
                            setCards={setCards}
                            setCardOrder={setCardOrder}
                        />
                    </ScrollContainer>
                </div>
            </Block>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        colorList: state.JobReducer.colorList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getColorList: () => dispatch(getColorList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyShopInformation);