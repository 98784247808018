import React, { useState, useEffect, setState } from 'react'
import {useHistory, Link} from 'react-router-dom'
import { Button, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Input, Alert } from 'reactstrap'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { notification } from 'antd'
import { useForm, Controller} from 'react-hook-form'
import { OutlinedButton, FilledBrightButton } from '@oneblinktech-org/helios-base'
import states from '../../fakeData/state'
import { CUSTOMER_TYPE_ID, getCookie } from '../../helpers/globals'
import {
    createCustomer
} from '../../store/job/actions'

const CustomerModal = (props) => {
    const [customer, setCustomer] = useState({})
    const { register, handleSubmit, setValue, getValues, control, watch, formState: { errors } } = useForm({
        defaultValues: {
            custom_state: '',
            phone_number: '',
        }
    })

    useEffect (() => {
        if(props.customer.message) {
            setCustomer({})
            notification.open({
                message: 'Alert!',
                description:
                    'New customer created successfully!',
                onClick: () => {
                },
            });
            props.closeModal()
            resetData()
        }
    }, [props.customer.message])

    const handleInputChange = (e) => {
        const target = e.target;
        let tmp = []
        tmp[target.name] = target.value
        setCustomer({...customer, ...tmp})
    }

    const handleAlternatePhoneChange = (e) => {
        setCustomer({...customer, alternate_phone: e.value})
    }

    const onCancelClick = () => {
        props.closeModal()
    }

    const watchAllFields = watch()

    const {ref: emailRef, ...emailRest} = register("email", {required: true})
    const {ref: firstnameRef, ...firstnameRest} = register("firstname", {required: true})
    const {ref: lastnameRef, ...lastnameRest} = register("lastname", {required: true})
    const {ref: addressRef, ...addressRest} = register("address", {required: true})
    const {ref: cityRef, ...cityRest} = register("city", {required: true})
    const {ref: customStateRef, ...customStateRest} = register("custom_state", {required: true})

    const phoneNumber = watch('phone_number', '', {required: true})

    const onSubmitCustomer = (data) => {
        let customerData = {
            email: data.email,
            firstname: data.firstname,
            lastname: data.lastname,
            company_name: customer?.helios_user_setting?.company_name ? customer?.helios_user_setting?.company_name : '',
            address: data.address,
            city: data.city,
            state: data.custom_state,
            phone_number: data?.helios_user_setting?.phone_number,
            alternate_phone: customer?.helios_user_setting?.alternate_phone ? customer?.helios_user_setting?.alternate_phone : '',
            type_id: CUSTOMER_TYPE_ID,
            organization_id: getCookie('active_organization_id')
        }

        props.submitCustomer(customerData)
    }

    const resetData = () => {
        setValue('email', '')
        setValue('firstname', '')
        setValue('lastname', '')
        setValue('address', '')
        setValue('city', '')
        setValue('custom_state', '')
        setValue('phone_number', '')
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmitCustomer)} autoComplete="off">
                <div className="p-4">
                    {
                        (props?.customer?.errors?.data?.errors != null && Object.keys(props?.customer?.errors?.data?.errors).length > 0) &&
                        Object.keys(props?.customer?.errors?.data?.errors).map((objKey, key) => {
                            return (
                                <Alert color="danger" key={key}><div>{props?.customer?.errors?.data?.errors[objKey]}</div></Alert>
                            )
                        })
                    }
                    {
                        Object.keys(props?.customer?.errors).length > 0 && props?.customer?.errors?.status === 500 &&
                        <Alert color="danger">Try again later.</Alert>
                    }
                    <div className="divider-label">
                        <h6><span className="pr-2">Customer Details</span></h6>
                    </div>
                    <div className="row mt-3 insurance-detail">
                        <FormGroup className="gray-bg col-12 col-md-12 custom-form-wrap">
                            <Controller
                                name={"email"}
                                control={control}
                                innerRef={register("email", { required: true })}
                                render={({}) => {
                                    return (
                                        <Input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="select-box-bottom-line"
                                            autoComplete="email"
                                            innerRef={ emailRef }
                                            { ...emailRest }
                                        />
                                    )
                                }}
                            />
                            {errors.email && <small className="text-danger error-text">This field is required</small>}
                            <label className={getValues('email') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="email">Email*</label>
                        </FormGroup>
                    </div>
                    <div className="row mt-3 insurance-detail">
                        <FormGroup className="gray-bg col-12 col-md-6 custom-form-wrap">
                            <Controller
                                name={"firstname"}
                                control={control}
                                innerRef={register("firstname", { required: true })}
                                render={({}) => {
                                    return (
                                        <Input
                                            type="text"
                                            name="firstname"
                                            id="firstname"
                                            className="select-box-bottom-line"
                                            autoComplete="firstname"
                                            innerRef={ firstnameRef }
                                            { ...firstnameRest }
                                        />
                                    )
                                }}
                            />
                            {errors.firstname && <small className="text-danger error-text">This field is required</small>}
                            <label className={getValues('firstname') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="firstname">First Name*</label>
                        </FormGroup>
                        <FormGroup className="gray-bg col-12 col-md-6 custom-form-wrap">
                            <Controller
                                name={"lastname"}
                                control={control}
                                innerRef={register("lastname", { required: true })}
                                render={({}) => {
                                    return (
                                        <Input
                                            type="text"
                                            name="lastname"
                                            id="lastname"
                                            className="select-box-bottom-line"
                                            autoComplete="lastname"
                                            innerRef={ lastnameRef }
                                            { ...lastnameRest }
                                        />
                                    )
                                }}
                            />
                            {errors.lastname && <small className="text-danger error-text">This field is required</small>}
                            <label className={getValues('lastname') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="lastname">Last Name*</label>
                        </FormGroup>
                    </div>
                    <div className="row mt-3 insurance-detail">
                        <FormGroup className="gray-bg col-12 col-md-12 custom-form-wrap">
                            <Input type="text" name="company_name" className="select-box-bottom-line" value={customer?.helios_user_setting?.company_name || ''}
                                    onChange={handleInputChange}
                            />
                            <label className={customer?.helios_user_setting?.company_name ? "custom-form-label field-active" : "custom-form-label"} htmlFor="company_name">Company (optional)</label>
                        </FormGroup>
                    </div>
                    <div className="row mt-3 insurance-detail">
                        <FormGroup className="gray-bg col-12 col-md-4 custom-form-wrap">
                            <Controller
                                name={"address"}
                                control={control}
                                innerRef={register("address", { required: true })}
                                render={({}) => {
                                    return (
                                        <Input
                                            type="text"
                                            name="address"
                                            id="address"
                                            className="select-box-bottom-line"
                                            autoComplete="address"
                                            innerRef={ addressRef }
                                            { ...addressRest }
                                        />
                                    )
                                }}
                            />
                            {errors.address && <small className="text-danger error-text">This field is required</small>}
                            <label className={getValues('address') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="address">Address*</label>
                        </FormGroup>
                        <FormGroup className="gray-bg col-12 col-md-4 custom-form-wrap">
                            <Controller
                                name={"city"}
                                control={control}
                                innerRef={register("city", { required: true })}
                                render={({}) => {
                                    return (
                                        <Input
                                            type="text"
                                            name="city"
                                            id="city"
                                            className="select-box-bottom-line"
                                            autoComplete="city"
                                            innerRef={ cityRef }
                                            { ...cityRest }
                                        />
                                    )
                                }}
                            />
                            {errors.city && <small className="text-danger error-text">This field is required</small>}
                            <label className={getValues('city') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="city">City*</label>
                        </FormGroup>
                        <FormGroup className="col-12 col-md-4 custom-form-wrap">
                            <Input
                                type="select"
                                name="custom_state"
                                id="custom_state"
                                className="select-box-blue-round gray-bg"
                                autoComplete="select"
                                bsSize="default"
                                innerRef={customStateRef}
                                {...customStateRest}
                            >
                                {
                                    states.map((item, index) => {
                                        return (
                                            <option value={item.name} key={index}>{item.name}</option>
                                        )
                                    })
                                }
                            </Input>
                            {errors.custom_state && <small className="text-danger error-text">This field is required</small>}
                            <label className={getValues('custom_state') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="custom_state">State</label>
                        </FormGroup>
                    </div>
                    <div className="row mt-3 borde-gray-bottom pb-3 insurance-detail">
                        <FormGroup className="gray-bg col-12 col-md-4 custom-form-wrap">
                            <Controller
                                name={"phone_number"}
                                control={control}
                                innerRef={register("phone_number", { required: true })}
                                render={() => {
                                    return (
                                        <NumberFormat
                                            className="select-box-bottom-line form-control"
                                            placeholder=""
                                            format="(###) ###-####"
                                            name="phone_number"
                                            id="phone_number"
                                            autoComplete="phone_number"
                                            mask="_"
                                            onValueChange={(value) => setValue("phone_number", value.value)}
                                            value={phoneNumber || ''}
                                        />
                                    )
                                }}
                            />
                            {errors.phone_number && <small className="text-danger error-text">This field is required</small>}
                            <label className={getValues('phone_number') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="phone_number">Main Phone*</label>
                        </FormGroup>
                        <FormGroup className="gray-bg col-12 col-md-4 custom-form-wrap">
                            <NumberFormat value={customer?.helios_user_setting?.alternate_phone || ''} onValueChange={handleAlternatePhoneChange} name="alternate_phone" autoComplete="off" className="select-box-bottom-line form-control" format="(###) ###-####" mask="_" />
                            {errors.alternate_phone && <small className="text-danger error-text">This field is required</small>}
                            <label className={customer?.helios_user_setting?.alternate_phone ? "custom-form-label field-active" : "custom-form-label"} htmlFor="alternate_phone">Alternate Phone</label>
                        </FormGroup>
                    </div>
                </div>
                <ModalFooter>
                    <OutlinedButton
                        disabled={props.customer.loading}
                        title= "Cancel"
                        clickEvent={(e) => onCancelClick()}
                    />
                    <FilledBrightButton
                        disabled={props.customer.loading}
                        title= "Save Customer"
                        type="submit"
                        clickEvent={(e) => {}}
                    />
                </ModalFooter>
            </form>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        customer: state.JobReducer.customer
    }
}

const mapDispachToProps = (dispatch) => ({
    submitCustomer: (data) => dispatch(createCustomer(data)),
})

export default connect(mapStateToProps, mapDispachToProps)(CustomerModal)