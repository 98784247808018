export const GET_VEHICLE_MAKES = "GET_VEHICLE_MAKES"
export const GET_JOBS = "GET_JOBS"
export const GET_CHOOSE_JOB = "GET_CHOOSE_JOB"
export const CREATE_NEW_JOB = "CREATE_NEW_JOB"
export const UPDATE_JOB = "UPDATE_JOB"
export const DEL_JOB = "DEL_JOB"
export const INITIAL_STATES = "INITIAL_STATES"
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const CREATE_CUSTOMER = "CREATE_CUSTOMER"
export const GET_JOB_STATUSES = "GET_JOB_STATUSES"
export const GET_JOB_BY_ORG = "GET_JOB_BY_ORG"
export const GET_ASSIGNED_USERS = "GET_ASSIGNED_USERS"
export const GET_JOB_COLORS = "GET_JOB_COLORS"
export const UPDATE_JOB_STATUS = "UPDATE_JOB_STATUS"
export const UPLOAD_JOB_VEHICLE_IMG = "UPLOAD_JOB_VEHICLE_IMG"
export const GET_JOB_VEHICLE_IMG = "GET_JOB_VEHICLE_IMG"
export const GET_ASSIGNABLE_USERS = "GET_ASSIGNABLE_USERS"
export const GET_PLANNING_JOBS = "GET_PLANNING_JOBS"
export const SCHEDULE_PLANNING_JOBS  = "SCHEDULE_PLANNING_JOBS"
export const SEARCHKEY  = "SEARCHKEY"
export const GET_JOB_FILTERS  = "GET_JOB_FILTERS"