import { takeLatest, put, call } from 'redux-saga/effects'
import { 
    GET_VEHICLE_MAKES, 
    CREATE_NEW_JOB, 
    GET_JOBS,
    GET_PLANNING_JOBS,
    GET_CHOOSE_JOB, 
    UPDATE_JOB, 
    DEL_JOB, 
    GET_CUSTOMERS, 
    CREATE_CUSTOMER,
    GET_JOB_STATUSES,
    GET_JOB_COLORS,
    UPDATE_JOB_STATUS,
    GET_JOB_FILTERS,
    UPLOAD_JOB_VEHICLE_IMG,
    GET_JOB_VEHICLE_IMG,
    GET_JOB_BY_ORG,
    GET_ASSIGNED_USERS,
    GET_ASSIGNABLE_USERS,
    SCHEDULE_PLANNING_JOBS,
    SEARCHKEY
} from './actionTypes'

import {
    getVehicleMakesSuccess,
    getFailed,
    getJobsSuccess,
    getJobsFailed,
    createNewJobSuccess,
    createNewJobFailed,
    getChooseJobSuccess,
    getChooseJobFailed,
    delJobSuccess,
    delJobFailed,
    getCustomersSuccess,
    getCustomersFailed,
    createCustomerSuccess,
    createCustomerFailed,
    getJobStatuesSuccess,
    getJobStatuesFailed,
    getColorListSuccess,
    getColorListFailed,
    updateJobStatusSuccess,
    updateJobStatusFailed,
    uploadVehicleImgsSuccess,
    uploadVehicleImgsFailed,
    getJobVehicleImgsSuccess,
    getJobVehicleImgsFailed,
    getAssignedUsersSuccess,
    getAssignedUsersFailed,
    getAllAssignableUsersSuccess,
    getAllAssignableUsersFailed,
    getPlanningJobsSuccess,
    getPlanningJobsFailed,
    scheduleJobsSuccess,
    scheduleJobsFailed,
    getFilterListSuccess,
    getFilterListFailed
} from "./actions"

import { 
    vehicleMakes,
    createNewJob,
    updateJob,
    getJobs,
    getPlanningJobs,
    getChooseJob,
    delJob,
    getCustomers,
    createCustomer,
    getJobStatuses,
    getJobColorList,
    updateJobStatus,
    uploadVehicleImgs,
    getVehicleImgs,
    getJobByOrg,
    getAssignedUsers,
    getCustomer,
    getAssignableUsers,
    schedulePlanningJobs,
    searchKey,
    getJobCustomerList
} from "../../services/job";

function* onVehicleMakes() {

    try {
        const response = yield call(vehicleMakes);
        yield put(getVehicleMakesSuccess(response));
    } catch (error) {
        yield put(getFailed(error.response));
    }
}

function* onJobs(data) {

    try {
        const response = yield call(getJobs, data.offset);
        yield put(getJobsSuccess(response));
    } catch (error) {
        yield put(getJobsFailed(error.response));
    }
}

function* onPlanningJobs(data) {

    try {
        const response = yield call(getPlanningJobs, data.dayDuration);
        yield put(getPlanningJobsSuccess(response));
    } catch (error) {
        yield put(getPlanningJobsFailed(error));
    }
}

function* onScheduleJobs(data) {

    try {
        const response = yield call(schedulePlanningJobs, data.payload);
        yield put(scheduleJobsSuccess(response));
    } catch (error) {
        yield put(scheduleJobsFailed(error));
    }
}

function* onCreateNewJob(data) {

    try {
        const response = yield call(createNewJob, data.payload);
        yield put(createNewJobSuccess(response, 0));
    } catch (error) {
        yield put(createNewJobFailed(error, 0));
    }
}

function* onUpdateJob(data) {

    try {
        const response = yield call(updateJob, data.payload);
        yield put(createNewJobSuccess(response, 1));
    } catch (error) {
        yield put(createNewJobFailed(error, 1));
    }
}

function* onChooseJob(data, loading) {

    try {
        const response = yield call(getChooseJob, data.id, loading);
        yield put(getChooseJobSuccess(response));
    } catch (error) {
        yield put(getChooseJobFailed(error));
    }
}

function* onDelJob(data) {

    try {
        yield call(delJob, data.id);
        const response = yield call(getJobs, 0);
        yield put(getJobsSuccess(response));
    } catch (error) {
        yield put(delJobFailed(error));
    }
}

function* onGetCustomers(data) {

    try {
        const response = yield call(getCustomer, data.payload);
        yield put(getCustomersSuccess(response));
    } catch (error) {
        yield put(getCustomersFailed(error));
    }
}

function* onGetAllAssignableUsers(data) {

    try {
        const response = yield call(getAssignableUsers, data.payload);
        yield put(getAllAssignableUsersSuccess(response));
    } catch (error) {
        yield put(getAllAssignableUsersFailed(error));
    }
}

function* onCreateCustomers(data) {

    try {
        const response = yield call(createCustomer, data.payload);
        yield put(createCustomerSuccess(response));
    } catch (error) {
        yield put(createCustomerFailed(error));
    }
}

function* onGetJobStatuses() {

    try {
        const response = yield call(getJobStatuses);
        yield put(getJobStatuesSuccess(response));
    } catch (error) {
        yield put(getJobStatuesFailed(error));
    }
}

function* onGetJobByOrg() {

    try {
        const response = yield call(getJobByOrg);
        yield put(getJobStatuesSuccess(response));
    } catch (error) {
        yield put(getJobStatuesFailed(error));
    }
}

function* onGetAssignedUsers() {

    try {
        const response = yield call(getAssignedUsers);
        yield put(getAssignedUsersSuccess(response));
    } catch (error) {
        yield put(getAssignedUsersFailed(error));
    }
}

function* onGetJobColors() {

    try {
        const response = yield call(getJobColorList);
        yield put(getColorListSuccess(response));
    } catch (error) {
        yield put(getColorListFailed(error));
    }
}

function* onGetJobFilterList() {

    try {
        const response = yield call(getJobCustomerList);
        yield put(getFilterListSuccess(response));
    } catch (error) {
        yield put(getFilterListFailed(error));
    }
}

function* onUpdateJobStatus(data) {

    try {
        const response = yield call(updateJobStatus, data.payload);
        yield put(updateJobStatusSuccess());
    } catch (error) {
        yield put(updateJobStatusFailed(error));
    }
}

function* onUploadVehicleImgs(data) {
    try {
        const response = yield call(uploadVehicleImgs, data.payload);
        yield put(uploadVehicleImgsSuccess(response.data))
    } catch (error) {
        yield put(uploadVehicleImgsFailed());
    }
}

function* onGetVehicleImgs(data) {
    try {
        const response = yield call(getVehicleImgs, data.id);
        yield put(getJobVehicleImgsSuccess(response))
    } catch (error) {
        yield put(getJobVehicleImgsFailed());
    }
}

function* onSearchKey(data) {
    try {
        const response = yield call(searchKey, data.key);
        yield put(getJobVehicleImgsSuccess(response))
    } catch (error) {
        yield put(getJobVehicleImgsFailed());
    }
}

function* JobSaga() {
    yield takeLatest(GET_VEHICLE_MAKES, onVehicleMakes)
    yield takeLatest(CREATE_NEW_JOB, onCreateNewJob)
    yield takeLatest(UPDATE_JOB, onUpdateJob)
    yield takeLatest(GET_JOBS, onJobs)
    yield takeLatest(GET_PLANNING_JOBS, onPlanningJobs)
    yield takeLatest(GET_CHOOSE_JOB, onChooseJob)
    yield takeLatest(DEL_JOB, onDelJob)
    yield takeLatest(GET_CUSTOMERS, onGetCustomers)
    yield takeLatest(GET_ASSIGNABLE_USERS, onGetAllAssignableUsers)
  yield takeLatest(CREATE_CUSTOMER, onCreateCustomers)
    yield takeLatest(GET_JOB_STATUSES, onGetJobStatuses)
    yield takeLatest(GET_JOB_BY_ORG, onGetJobByOrg)
    yield takeLatest(GET_ASSIGNED_USERS, onGetAssignedUsers)
  yield takeLatest(GET_JOB_COLORS, onGetJobColors)
  yield takeLatest(GET_JOB_FILTERS, onGetJobFilterList)
    yield takeLatest(UPDATE_JOB_STATUS, onUpdateJobStatus)
    yield takeLatest(UPLOAD_JOB_VEHICLE_IMG, onUploadVehicleImgs)
    yield takeLatest(GET_JOB_VEHICLE_IMG, onGetVehicleImgs)
    yield takeLatest(SCHEDULE_PLANNING_JOBS, onScheduleJobs)
    yield takeLatest(SEARCHKEY, onSearchKey)
}

export default JobSaga;